<template>
  <v-container v-if="hasPermission($route.meta.permission)">
    <v-row>
      <v-col>
        <v-form v-model="isFormValid">
          <v-container id="UpdateRole" class="munjz-bg-grey-dark px-4" tag="section">
            <v-row class="ma-0">
              <v-row class="ma-0 munjz-bg-grey">
      				<v-col cols="10" sm="8" class="d-flex align-center me-auto">
									<span
										>Roles & Permissions
										<v-icon>mdi-chevron-right</v-icon></span
									>
									<span class="text-sm-h2 text-h3">Create Role</span>
								</v-col>

								<v-col cols="2" sm="1" class="d-flex justify-end pa-0">
									<v-btn
										small
										class="blue-grey lighten-4 ma-2"
										fab
										text
										icon
										to="/roles"
										><v-icon>mdi-close</v-icon></v-btn
									>
								</v-col>

              </v-row>
              <v-col cols="12" class="pr-0 pl-0">
                <v-text-field
                  v-model="role_name"
				  		  :rules="[val=>val.length>0]"
                  label="Role Name"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pr-0 pl-0">
                <v-textarea
                  v-model="role_description"
                  label="Role Description"
                  required
                  outlined
                  dense
                ></v-textarea>
              </v-col>
              <v-col cols="12" class="pr-0 pl-0">
                <v-autocomplete
                  v-model="selected_teams"
                  :items="teams"
                  required
                  item-text="name"
				  :rules="[val=>val.length>0]"
                  item-value="id"
                  label="Select Team members"
                  outlined
                  dense
                  multiple
                  small-chips
                ></v-autocomplete>
              </v-col>
			 <v-col cols="12"><h3 class="mt-10">Permissions</h3></v-col>
              <v-col cols="12" class="">
                <v-row
                  class="ma-0"
                  justify="center"
                  v-for="(permissions, group,idx) in partner_permissions"
				  :key="idx"
                >
                  <v-col cols="12" class="pa-0 mt-10 mb-2">
                    <div class="d-flex align-center">
                      <v-icon small class="me-1" color="primary"
                        >mdi-checkbox-blank</v-icon
                      >
                      <h4 class="ma-0">
                        {{ group }}
                      </h4>
                    </div>
                  </v-col>
                  <v-col cols="12" class="pa-0">
                    <v-checkbox
                      class="mt-1 ms-0"
                      hide-details
                    
                      v-for="(permission,idx) in permissions"
					:key="idx"
                      v-model="selected_permissions"
                      :label="permission.name"
                      :value="permission.id"
                    ></v-checkbox>
                  </v-col>
                </v-row>

                <v-col cols="12" class="d-flex justify-center">

                  <v-btn
                
                    class="me-0"
                    color="primary"
                    @click="CreateRole"
					:disabled="!isFormValid"
                    ><v-icon>mdi-plus</v-icon> Create Role
                  </v-btn>
                </v-col>
              </v-col>

              <v-snackbar rounded v-model="snackbar.show" centered :color="snackbar.color">
                  <v-icon>{{snackbar.icon}}</v-icon>
                {{ snackbar.text }}

                <template v-slot:action="{ attrs }">
                  <!-- <v-btn color="white" text v-bind="attrs" @click="snackbar.show = false">
                    Close
                  </v-btn> -->
                </template>
              </v-snackbar>
            </v-row>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
      </v-row>
</v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
export default {
	data() {
		return {
			snackbar: {
				text: "",
				color: "",
				icon: "",
				show: false
			},
			isFormValid: false,
			isLoading: false,
			role_name: "",
			role_description: "",
			partner_permissions: [],
			selected_permissions: [],
			teams: [],
			selected_teams: [],
			user_id: localStorage.getItem("userid"),
			token: localStorage.getItem("token")
		};
	},
	mounted() {
		this.getPermissions();
		this.getTeams();
	},

	watch: {},
	methods: {
		showSnackbar(message, color, icon) {
			this.snackbar.show = true;
			this.snackbar.text = message;
			this.snackbar.color = color;
			this.snackbar.icon = icon;
		},
		getPermissions() {
			//  alert("user"+this.user_id)
			axios
				.get("/api/v3/b2b/permissions/list", {
					headers: { "X-Authorization": this.token }
				})
				.then(res => {
					this.partner_permissions = res.data.permissions;
				})
				.catch(err => console.log(err));
		},
		getTeams() {
			//  alert("user"+this.user_id)
			axios
				.get("/api/v3/get-partner-admins", {
					headers: { "X-Authorization": this.token },
					params: {
						user_id: this.user_id
					}
				})
				.then(res => {
					this.teams = res.data.teams;
				})
				.catch(err => console.log(err));
		},
		CreateRole() {
			axios
				.post(
					"/api/v3/b2b/roles/store",
					{
						role_name: this.role_name,
						role_description: this.role_description,
						selected_permissions: this.selected_permissions,
						// to be removed
						selected_partner_admins: [],
						user_id: this.user_id
					},
					{
						headers: { "X-Authorization": this.token }
					}
				)
				.then(response => {
					if (response.data.status == 200) {
						this.showSnackbar("response.data.message", "green", "mdi-check");
						setTimeout(() => {
							this.$router.push({
								path: "/roles"
							});
						}, 3000);
					} else {
						this.showSnackbar(response.data.message, "red", "mdi-close");
					}
				})
				.catch(error => {
					if (error.response.data.status_code) {
						this.$router.push("/roles");
					}
					this.showSnackbar("Something went wrong", "red", "mdi-close");
				});
		}
	}
};
</script>


